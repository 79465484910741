html,
body,
#root {
  height: 100%;
}

/* Global overrides */
.MuiBackdrop-root:not(.MuiBackdrop-invisible) {
  background-color: rgba(29, 29, 27, 0.4) !important;
}

.MuiButton-root {
  box-shadow: none !important;
}
